import React from "react";
import { Subscription } from "rxjs";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import App from "./app";

import DemoSettingsView from "./components/views/demo-settings-view";

import { DemoService } from "./services/demo.service";

export default class Demo extends React.Component {
  state = {
    demoState: null
  };
  demoStateSubscription: Subscription | null = null;

  componentDidMount() {
    this.demoStateSubscription = DemoService.observeDemoState$().subscribe(
      demoState => {
        this.setState({ demoState }, async () => {
          if (this.state.demoState === DemoService.DEMO_STATES.INITIALIZING) {
            await DemoService.init();
          }
        });
      }
    );
  }

  componentWillUnmount() {
    if (this.demoStateSubscription) {
      this.demoStateSubscription.unsubscribe();
    }
  }

  render() {
    return (
      <>
        {this.state.demoState === DemoService.DEMO_STATES.INITIALIZED && (
          <Router>
            <Redirect to="/" from="/*" />
            <Route path="/" component={DemoSettingsView} />
          </Router>
        )}
        {this.state.demoState === DemoService.DEMO_STATES.READY && <App />}
      </>
    );
  }
}
