import React, { Component } from "react";

import View from "../layout/view";
import Content from "../layout/content";

import Form from "../form/form";
import {
  DEMO_SETTINGS_FIELDS,
  SettingsService
} from "../../services/settings.service";

import { DemoService } from "../../services/demo.service";
import { AppService } from "../../services/app.service";
import { Subscription } from "rxjs";
import { DemoSettings } from "../../models/settings.types";
import { IFormValues } from "../form/form.service";

interface IDemoSettingsViewState {
  settings: DemoSettings | undefined;
  loading: boolean;
}

export default class DemoSettingsView extends Component<
  {},
  IDemoSettingsViewState
> {
  state = {
    settings: undefined,
    loading: true
  };

  settingsSubscription: Subscription | null = null;

  componentDidMount() {
    this.settingsSubscription = SettingsService.observeSettings$().subscribe(
      (settings: DemoSettings) => {
        this.setState({ settings: { ...settings }, loading: false });
      }
    );
  }

  componentWillUnmount() {
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }

  render() {
    return (
      <View>
        <Content title="Auth0 Demo Settings">
          {!this.state.loading && (
            <Form
              fields={DEMO_SETTINGS_FIELDS}
              values={this.state.settings}
              onSubmit={async (payload: IFormValues) => {
                const settings: DemoSettings = {
                  apiUrl: payload.apiUrl,
                  audience: payload.audience,
                  callbackUrl: payload.callbackUrl,
                  clientId: payload.clientId,
                  domain: payload.domain
                };

                await SettingsService.save(settings);
                await DemoService.transitionToReady();
                await AppService.transitionToInitializing();
              }}
              onClear={async () => {
                await SettingsService.clear();
              }}
            />
          )}
        </Content>
      </View>
    );
  }
}
