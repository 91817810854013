import { BehaviorSubject, Observable } from "rxjs";

import { MenuService } from "./menu.service";
import { AuthService } from "./auth.service";
import { AppStates } from "../models/app.types";

class _AppService {
  APP_STATES: AppStates = {
    IDLE: "idle",
    INITIALIZING: "initializing",
    INITIALIZED: "initialized"
  };

  private appStateSubject: BehaviorSubject<string> = new BehaviorSubject(
    this.APP_STATES.INITIALIZING
  );
  observeAppState$ = (): Observable<string> =>
    this.appStateSubject.asObservable();

  get appState(): string {
    return this.appStateSubject.getValue();
  }

  set appState(newState: string) {
    this.appStateSubject.next(newState);
  }

  init = async (): Promise<void> => {
    await MenuService.fetchMenu();
    await AuthService.init();
    await this.transitionToInitialized();
  };

  transitionToIdle = async (): Promise<void> => {
    AppService.appState = this.APP_STATES.IDLE;
  };

  transitionToInitializing = async (): Promise<void> => {
    AppService.appState = this.APP_STATES.INITIALIZING;
  };

  transitionToInitialized = async (): Promise<void> => {
    AppService.appState = this.APP_STATES.INITIALIZED;
  };

  isIdle = (): boolean => this.appState === this.APP_STATES.IDLE;
  isInitializing = (): boolean =>
    this.appState === this.APP_STATES.INITIALIZING;
  isInitialized = (): boolean => this.appState === this.APP_STATES.INITIALIZED;
}

export const AppService: _AppService = new _AppService();
