import React from "react";
import { NavLink } from "react-router-dom";
import { combineLatest, Subscription } from "rxjs";

import FormButton from "../form/form-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";

import { AuthService } from "../../services/auth.service";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { UserInfo } from "../../models/auth.types";

import "./nav-bar.scss";

interface INavBarMenuLinkProps {
  to: string;
  icon: IconProp;
  label: string;
}

interface INavBarState {
  isAuth: boolean | undefined;
  userInfo: UserInfo | undefined;
}

interface INavBarProps {
  appName: string;
}

const NavBarHeader: React.FC = props => (
  <div className="NavBar__header">{props.children}</div>
);

const NavBarMenu: React.FC = props => (
  <div className="NavBar__menu">{props.children}</div>
);

const NavBarMenuItem: React.FC = props => (
  <div className="NavBar__menuItem">{props.children}</div>
);

const NavBarMenuLink: React.FC<INavBarMenuLinkProps> = props => {
  return (
    <NavLink
      className="NavBar__menuLink-wrapper Effect__linkFade"
      to={props.to}
      activeClassName="NavBar__menuLink--active"
    >
      <div className="NavBar__menuLink">
        <div className="NavBar__menuLink-icon">
          <FontAwesomeIcon icon={props.icon} size="lg" />
        </div>
        <span className="NavBar__menuLink-label">{props.label}</span>
      </div>
    </NavLink>
  );
};

const NavBarList: React.FC = props => (
  <div className="NavBar__list">{props.children}</div>
);

const NavBarFooter: React.FC = props => (
  <div className="NavBar__footer">{props.children}</div>
);

const NavBarSessionInfo: React.FC = props => (
  <div className="NavBar__userInfo">
    <NavLink
      className="NavBar__userInfo-link"
      to="/user-profile"
      activeClassName="NavBar__userInfo-link--active"
    >
      <div className="NavBar__userInfo-avatar">
        <FontAwesomeIcon icon={faUser} size="lg" />
      </div>
      <span className="NavBar__userInfo-name">{props.children}</span>
    </NavLink>
  </div>
);

export default class NavBar extends React.Component<INavBarProps> {
  authSub: Subscription | null = null;
  userSub: Subscription | null = null;
  dataSub: Subscription | null = null;

  state: INavBarState = {
    isAuth: undefined,
    userInfo: undefined
  };

  async componentDidMount() {
    this.dataSub = combineLatest([
      AuthService.observeIsAuth$(),
      AuthService.observeUser$()
    ]).subscribe((data: [boolean, UserInfo]) => {
      const isAuth = data[0];
      const userInfo = data[1];

      this.setState({ isAuth, userInfo });
    });
  }

  componentWillUnmount() {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  render() {
    return (
      <nav id="NavBar" role="navigation" aria-label="NavBar">
        <NavBarHeader>{this.props.appName}</NavBarHeader>
        <NavBarMenu>
          <NavBarMenuItem>
            <NavBarMenuLink to="/home" label="Home" icon={faHome} />
          </NavBarMenuItem>
          <NavBarMenuItem>
            <NavBarMenuLink to="/menu" label="Menu" icon={faUtensils} />
          </NavBarMenuItem>
          <NavBarMenuItem>
            <NavBarMenuLink to="/settings" label="Settings" icon={faCog} />
          </NavBarMenuItem>
        </NavBarMenu>
        <NavBarList />
        <NavBarFooter>
          {this.state.isAuth ? (
            <>
              <FormButton label="Sign Out" action={AuthService.logout} />
              <NavBarSessionInfo>
                {AuthService.userInfo.firstName || `WAB Teammate`}
              </NavBarSessionInfo>
            </>
          ) : (
            <FormButton
              label="Sign In"
              action={async () => await AuthService.login()}
            />
          )}
        </NavBarFooter>
      </nav>
    );
  }
}
