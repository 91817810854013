import createAuth0Client from "@auth0/auth0-spa-js";
import { BehaviorSubject, from, Observable } from "rxjs";

import { SettingsService } from "./settings.service";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import {
  Auth0UserInfoWithRoles,
  AuthStates,
  UserInfo
} from "../models/auth.types";
import { DemoSettings } from "../models/settings.types";

class _AuthService {
  AUTH_STATES: AuthStates = {
    READY: "ready",
    INITIALIZING: "initializing",
    INITIALIZED: "initialized"
  };

  MENU_ADMIN_ROLE: string = "menu-admin";

  _settings: DemoSettings | null = null;
  auth0: Auth0Client | null = null;

  _userInfo: UserInfo = {
    email: "",
    firstName: "",
    lastName: "",
    isMenuAdmin: false,
    picture: ""
  };

  currentLocation: string | null = null;

  private processUserInfo = (newUserInfo: Auth0UserInfoWithRoles): UserInfo => {
    const userInfo: UserInfo = {
      email: "",
      firstName: "",
      lastName: "",
      isMenuAdmin: false,
      picture: ""
    };

    const roles: string[] | string =
      newUserInfo[`${SettingsService.settings.audience}/roles`];

    const isMenuAdmin = roles ? roles.includes(this.MENU_ADMIN_ROLE) : false;

    userInfo.email = newUserInfo.email || "";
    userInfo.firstName = newUserInfo.given_name || newUserInfo.name || "";
    userInfo.lastName = newUserInfo.family_name || "";
    userInfo.isMenuAdmin = isMenuAdmin;
    userInfo.picture = newUserInfo.picture || "";

    return userInfo;
  };

  get userInfo(): UserInfo {
    return this._userInfo;
  }

  set userInfo(newUserInfo: UserInfo) {
    this._userInfo = newUserInfo;
    this.userSubject.next(this._userInfo);
  }

  private isAuthSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  observeIsAuth$ = (): Observable<boolean> => this.isAuthSubject.asObservable();

  userSubject: BehaviorSubject<UserInfo> = new BehaviorSubject(this.userInfo);
  observeUser$ = (): Observable<UserInfo> => this.userSubject.asObservable();

  authStateSubject: BehaviorSubject<string> = new BehaviorSubject(
    this.AUTH_STATES.INITIALIZING
  );
  observeAuthState$ = (): Observable<string> =>
    this.authStateSubject.asObservable();

  get settings(): DemoSettings | null {
    return this._settings;
  }

  set settings(newSettings: DemoSettings | null) {
    this._settings = newSettings;
  }

  get authState(): string {
    return this.authStateSubject.getValue();
  }

  set authState(newState: string) {
    this.authStateSubject.next(newState);
  }

  constructor() {
    SettingsService.observeSettings$().subscribe((settings: DemoSettings) => {
      if (settings) {
        this.settings = settings;
      }
    });
  }

  init = async (): Promise<void> => {
    if (this.settings) {
      from(
        createAuth0Client({
          domain: this.settings.domain,
          client_id: this.settings.clientId,
          redirect_uri: this.settings.callbackUrl,
          audience: this.settings.audience
        })
      ).subscribe(async (auth0Client: Auth0Client) => {
        if (auth0Client) {
          this.auth0 = auth0Client;

          if (window.location.search.includes("code=")) {
            const { appState } = await this.auth0.handleRedirectCallback();

            this.onRedirectCallback(appState);
          }

          const isAuthenticated: boolean = await this.auth0.isAuthenticated();

          this.isAuthSubject.next(isAuthenticated);

          if (isAuthenticated) {
            this.userInfo = this.processUserInfo(await this.auth0.getUser());
          }

          this.transitionToReady();
        }
      });
    }
  };

  onRedirectCallback = (appState: any) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  login = async (): Promise<void> => {
    if (this.auth0) {
      try {
        await this.auth0.loginWithPopup();
        this.isAuthSubject.next(await this.auth0.isAuthenticated());

        if (this.isAuthSubject.getValue()) {
          this.userInfo = this.processUserInfo(await this.auth0.getUser());
        }

        this.transitionToReady();
      } catch (e) {
        console.log(e);
      }
    }
  };

  logout = async (): Promise<void> => {
    if (this.auth0 && this.settings) {
      this.currentLocation = window.location.href;

      await this.auth0.logout({
        returnTo: this.settings.callbackUrl
      });
      this.isAuthSubject.next(await this.auth0.isAuthenticated());
      this.transitionToReady();
    }
  };

  getTokenSilently = async (): Promise<string | null> => {
    if (this.auth0) {
      const token: string = await this.auth0.getTokenSilently();

      return token ? token : null;
    }

    return null;
  };

  transitionToReady = async (): Promise<void> => {
    this.authState = this.AUTH_STATES.READY;
  };

  isInitializing = (): boolean =>
    this.authState === this.AUTH_STATES.INITIALIZING;
  isReady = (): boolean => this.authState === this.AUTH_STATES.READY;
}

export const AuthService = new _AuthService();
