import { BehaviorSubject, Observable } from "rxjs";
import * as localForage from "localforage";
import { DemoSettings, SettingsStateFlags } from "../models/settings.types";
import { IFormFields } from "../components/form/form.service";

export const DB_SETTINGS_FLAG: string = "wab_setting_flag";
export const DB_SETTINGS: string = "wab_setting";

export const SETTINGS_STATE_FLAGS: SettingsStateFlags = {
  SET: "set",
  UNSET: "unset"
};

export const SETTINGS_FIELDS: IFormFields = {
  domain: {
    id: "domain",
    name: "Auth0 Domain"
  },
  clientId: {
    id: "clientId",
    name: "Auth0 Client ID"
  },
  callbackUrl: {
    id: "callbackUrl",
    name: "Auth0 Callback URL"
  },
  audience: {
    id: "audience",
    name: "API Audience"
  },
  apiUrl: {
    id: "apiUrl",
    name: "API URL"
  }
};

export const DEMO_SETTINGS_FIELDS: IFormFields = {
  domain: {
    id: "domain",
    name: "Auth0 Domain",
    defaultValue: "",
    isRequired: true
  },
  clientId: {
    id: "clientId",
    name: "Auth0 Client ID",
    defaultValue: "",
    isRequired: true
  },
  callbackUrl: {
    id: "callbackUrl",
    name: "Auth0 Callback URL",
    defaultValue: "",
    isRequired: true
  },
  audience: {
    id: "audience",
    name: "Auth0 API Audience",
    defaultValue: "",
    isRequired: true
  },
  apiUrl: {
    id: "apiUrl",
    name: "Your API Base URL",
    defaultValue: "",
    isRequired: true
  }
};

export const DEMO_DEFAULT_SETTINGS: DemoSettings = {
  domain: "",
  clientId: "",
  callbackUrl: "http://localhost:3000/home",
  audience: "https://menu-api.demo.com",
  apiUrl: "http://localhost:7000"
};

const INITIAL_SETTINGS: DemoSettings = {
  domain: "",
  clientId: "",
  callbackUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/home"
      : "https://dashboard.whatabyte.now.sh/home",
  audience: "https://menu-api.demo.com",
  apiUrl: "http://localhost:7000"
};

class _SettingService {
  private settingsSub: BehaviorSubject<DemoSettings> = new BehaviorSubject(
    INITIAL_SETTINGS
  );
  observeSettings$ = (): Observable<DemoSettings> =>
    this.settingsSub.asObservable();

  get settings(): DemoSettings {
    return this.settingsSub.getValue();
  }

  set settings(newSettings: DemoSettings) {
    this.settingsSub.next(newSettings);
  }

  get apiUrl(): string {
    return this.settingsSub.getValue().apiUrl;
  }

  update = (settings: DemoSettings): void => {
    if (settings !== undefined && settings !== null) {
      this.settings = settings;
    }
  };

  save = async (settings: DemoSettings): Promise<void> => {
    if (settings !== undefined && settings !== null) {
      const invalidSettings: string[] = Object.values(settings).filter(
        setting => setting === ""
      );

      if (invalidSettings.length === 0) {
        await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.SET);
        await localForage.setItem(DB_SETTINGS, settings);

        this.settings = settings;
      }
    }
  };

  clear = async () => {
    const state: DemoSettings = { ...this.settingsSub.getValue() };

    Object.keys(state).map((key: string) => (state[key] = ""));

    await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.UNSET);

    this.settings = state;
  };

  validate = (): boolean => {
    const settingsState: DemoSettings = this.settingsSub.getValue();
    const settingsValues: string[] = Object.values(settingsState);

    if (settingsValues.length === 0) {
      return false;
    }

    for (let i = 0; i < settingsValues.length; i++) {
      if (settingsValues[i].length === 0) {
        return false;
      }
    }

    return true;
  };
}

export const SettingsService: _SettingService = new _SettingService();
