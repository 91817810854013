import React from "react";

import "./field-table.scss";

export interface ITableField {
  name: string;
  value: string;
}

export interface ITableFields {
  [id: string]: ITableField;
}

export interface IFieldTableProps {
  fields: ITableFields;
}

interface IFieldTableLabelProps {
  label: string;
}

interface IFieldTableValueProps {
  value: string;
}

const FieldTableLabel: React.FC<IFieldTableLabelProps> = (
  props: IFieldTableLabelProps
) => <div className="FieldTable__label">{props.label}</div>;

const FieldTableValue: React.FC<IFieldTableValueProps> = (
  props: IFieldTableValueProps
) => <div className="FieldTable__value">{props.value}</div>;

class FieldTable extends React.Component<IFieldTableProps> {
  render() {
    return (
      <div className="FieldTable">
        {Object.values(this.props.fields).map((field: ITableField) => {
          const { name, value } = { ...field };

          return (
            <div className="FieldTable__field" key={name}>
              <FieldTableLabel label={name} />
              <FieldTableValue value={value} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default FieldTable;
