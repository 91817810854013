import React from "react";

import Content from "../layout/content";
import Button from "../button";

import View from "../layout/view";

import { AuthService } from "../../services/auth.service";

import "./user-profile-view.scss";
import { Subscription } from "rxjs";
import { UserInfo } from "../../models/auth.types";

interface IAvatarProps {
  picture: string;
  firstName: string;
}

interface IUserProfileViewState {
  userInfo: UserInfo | undefined;
  loading: boolean;
}

const Avatar: React.FC<IAvatarProps> = (props: IAvatarProps) => {
  return (
    <img
      className="UserProfile__avatar"
      src={props.picture}
      alt={props.firstName}
    />
  );
};

export default class UserProfileView extends React.Component<{}> {
  state: IUserProfileViewState = {
    userInfo: undefined,
    loading: true
  };
  userSub: Subscription | null = null;

  componentDidMount() {
    this.userSub = AuthService.observeUser$().subscribe(
      (userInfo: UserInfo) => {
        this.setState({ loading: false, userInfo });
      }
    );
  }

  componentWillUnmount() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  render() {
    return (
      !this.state.loading &&
      this.state.userInfo && (
        <View>
          <Content title={this.state.userInfo.firstName}>
            <Avatar
              picture={this.state.userInfo.picture}
              firstName={this.state.userInfo.firstName}
            />
            <Button label="View Account" action={() => {}} />
            <Button label="Help" action={() => {}} />
          </Content>
        </View>
      )
    );
  }
}
