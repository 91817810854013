import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";

import "./grid-item.scss";

interface IGridItemProps {
  id: number;
  content: string;
  name: string;
  description: string;
}

const GridItem: React.FC<IGridItemProps> = (props: IGridItemProps) => (
  <div className="GridItem ">
    <Link
      className={"GridItem__link Effect__filterDarken"}
      style={{ display: "flex", flexDirection: "column" }}
      to={`/menu/${props.id}`}
    >
      <div className="GridItem__imageFrame">
        <div className="GridItem__imageFrame-placeholder">
          <FontAwesomeIcon icon={faUtensils} />
        </div>
        <div
          className="GridItem__imageFrame-content"
          style={{
            background: `url(${props.content})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto 100%",
            backgroundPosition: "center"
          }}
        />
      </div>
      <div className="GridItem__info">
        <span className="GridItem__name">{props.name}</span>
        <span className="GridItem__description">{props.description}</span>
      </div>
    </Link>
  </div>
);

export default GridItem;
