import React from "react";

import View from "../layout/view";
import Content from "../layout/content";
import Form from "../form/form";

import { MenuService } from "../../services/menu.service";

import { MenuItem } from "../../models/menu.types";
import { History } from "history";
import { IFormFields, IFormValues } from "../form/form.service";

interface IAddItemViewProps {
  history: History;
}

export default class AddItemView extends React.Component<IAddItemViewProps> {
  MENU_ITEM_FIELDS: IFormFields = {
    name: {
      id: "name",
      name: "Name",
      validation: {
        validator: (value: string) => {
          return /^[A-Za-z ]+$/.test(value);
        },
        invalidMessage: "Invalid name"
      },
      isRequired: true,
      defaultValue: "Spring Salad"
    },
    price: {
      id: "price",
      name: "Price",
      validation: {
        validator: (value: string) => {
          return /^([1-9]+[0-9]*|0)((\.[\d][\d])?)$/.test(value);
        },
        invalidMessage: "Invalid price"
      },
      isRequired: true,
      defaultValue: "5.99"
    },
    description: {
      id: "description",
      name: "Description",
      validation: {
        validator: (value: string) => {
          return /^[A-Za-z0-9 '".,;!?\-()]+$/.test(value);
        },
        invalidMessage: "Invalid description"
      },
      isRequired: true,
      defaultValue: "Fresh"
    },
    image: {
      id: "image",
      name: "Image URL",
      validation: {
        validator: (value: string) => {
          return /^(https:\/\/).+(\.[a-z]{2,3}\/).+(\.(jpg|jpeg|png))$/.test(
            value
          );
        },
        invalidMessage: "Invalid image URL"
      },
      isRequired: true,
      defaultValue: "https://cdn.auth0.com/blog/whatabyte/salad-sm.png"
    }
  };

  addMenuItem = (payload: IFormValues): void => {
    let id: number = -1;

    if (payload.id) {
      id = parseInt(payload.id, 10);
    }

    const item: MenuItem = {
      id,
      name: payload.name,
      description: payload.description,
      image: payload.image,
      price: parseFloat(payload.price)
    };

    MenuService.addMenuItem(item);
  };

  cancel = async (): Promise<void> => {
    this.props.history.push("/menu");
  };

  render() {
    return (
      <View>
        <Content title="Add Menu Item" actionName="Cancel" action={this.cancel}>
          <Form fields={this.MENU_ITEM_FIELDS} onSubmit={this.addMenuItem} />
        </Content>
      </View>
    );
  }
}
