import React from "react";

import "./menu-item.scss";
import View, { ViewStates } from "../layout/view";
import Content from "../layout/content";
import { MenuService } from "../../services/menu.service";
import Button from "../button";
import { AuthService } from "../../services/auth.service";
import { from, of, Subscription, zip } from "rxjs";
import { switchMap } from "rxjs/operators";
import { History } from "history";

interface IMenuItemState {
  isMenuAdmin: boolean;
  loading: boolean;
  viewStatus: ViewStates.Valid | ViewStates.NotFound | undefined;
}

interface IMenuItemProps {
  history: History;
  match: any;
}

class MenuItem extends React.Component<IMenuItemProps> {
  state: IMenuItemState = {
    isMenuAdmin: false,
    loading: true,
    viewStatus: undefined
  };

  dataSub: Subscription | null = null;

  async componentDidMount() {
    this.dataSub = AuthService.observeUser$()
      .pipe(
        switchMap((data: any) => {
          const {
            match: {
              params: { menuItemId }
            }
          } = this.props;

          const itemId = parseInt(menuItemId, 10);

          return zip(of(data), from(MenuService.getMenuItem(itemId)));
        })
      )
      .subscribe(async data => {
        const userInfo = data[0];
        const menuItem = data[1];
        let viewStatus;

        if (menuItem) {
          viewStatus = ViewStates.Valid;
        } else {
          viewStatus = ViewStates.NotFound;
        }

        this.setState({
          viewStatus,
          isMenuAdmin: userInfo.isMenuAdmin,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  back = async () => {
    this.props.history.push("/menu");
  };

  render() {
    return (
      !this.state.loading && (
        <>
          {this.state.viewStatus === ViewStates.NotFound && (
            <View viewStatus={this.state.viewStatus}>
              <Button
                label="View menu items"
                action={() => this.props.history.push("/menu")}
              />
            </View>
          )}
          {this.state.viewStatus === ViewStates.Valid &&
            MenuService.selectedMenuItem && (
              <View viewStatus={this.state.viewStatus}>
                <Content
                  title={MenuService.selectedMenuItem.name}
                  actionName="Back"
                  action={this.back}
                >
                  <div className="MenuItem">
                    <img
                      className="MenuItem__image"
                      src={MenuService.selectedMenuItem.image}
                      alt={`${MenuService.selectedMenuItem.name}`}
                    />
                    <span className="MenuItem__price">
                      ${MenuService.selectedMenuItem.price}
                    </span>
                    <div className="MenuItem__details">
                      <span className="MenuItem__description">
                        {MenuService.selectedMenuItem.description}
                      </span>
                    </div>
                    {this.state.isMenuAdmin && MenuService.selectedMenuItem && (
                      <div className="MenuItem__actions">
                        <Button
                          label="Edit"
                          size="xsmall"
                          action={() => {
                            MenuService.selectedMenuItem &&
                              this.props.history.push(
                                `/menu/${MenuService.selectedMenuItem.id}/edit-item/`
                              );
                          }}
                        />
                        <Button
                          label="Delete"
                          size="xsmall"
                          action={() =>
                            MenuService.selectedMenuItem &&
                            MenuService.deleteMenuItem(
                              MenuService.selectedMenuItem.id
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </Content>
              </View>
            )}
        </>
      )
    );
  }
}

export default MenuItem;
