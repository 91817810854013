import React, { ComponentClass, FunctionComponent } from "react";
import { AuthService } from "../services/auth.service";
import ProtectedRoute from "./protected-route";
import { Subscription } from "rxjs";

interface IMenuAdminRouteProps {
  path: string;
  component: ComponentClass<any, any> | FunctionComponent<any>;
  redirectPath?: string;
}

interface IMenuAdminRouteState {
  isMenuAdmin: boolean;
  loading: boolean;
}

class MenuAdminRoute extends React.Component<IMenuAdminRouteProps> {
  state: IMenuAdminRouteState = {
    isMenuAdmin: false,
    loading: true
  };

  userSub: Subscription | undefined;

  componentDidMount() {
    this.userSub = AuthService.observeUser$().subscribe(userInfo => {
      if (userInfo) {
        this.setState({ loading: false, isMenuAdmin: userInfo.isMenuAdmin });
      }
    });
  }

  componentWillUnmount() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  render() {
    const canActivate = this.state.isMenuAdmin;

    return (
      !this.state.loading && (
        <ProtectedRoute
          canActivate={canActivate}
          redirectPath={this.props.redirectPath}
          {...this.props}
        />
      )
    );
  }
}

export default MenuAdminRoute;
