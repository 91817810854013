import React, { ComponentClass, FunctionComponent } from "react";
import { AuthService } from "../services/auth.service";
import { Route, Redirect } from "react-router-dom";
import { Subscription } from "rxjs";

interface IProtectedRouteProps {
  component: ComponentClass<any, any> | FunctionComponent<any>;
  path: string;
  canActivate?: boolean;
  redirectPath?: string;
}

interface IProtectedRouteState {
  isAuth: boolean;
  loading: boolean;
}

class ProtectedRoute extends React.Component<
  IProtectedRouteProps,
  IProtectedRouteState
> {
  state: IProtectedRouteState = {
    isAuth: false,
    loading: true
  };

  isAuthSub: Subscription | undefined;

  componentDidMount() {
    this.isAuthSub = AuthService.observeIsAuth$().subscribe(
      (isAuth: boolean) => {
        this.setState({ isAuth, loading: false });
      }
    );
  }

  componentWillUnmount() {
    if (this.isAuthSub !== undefined) {
      this.isAuthSub.unsubscribe();
    }
  }

  render() {
    const Component = this.props.component;
    const canActivate =
      this.props.canActivate === undefined ? true : this.props.canActivate;

    return !this.state.loading ? (
      this.state.isAuth && canActivate ? (
        <Route
          path={this.props.path}
          render={() => <Component {...this.props} />}
        />
      ) : (
        <Redirect to={this.props.redirectPath || "/home"} from="" />
      )
    ) : null;
  }
}

export default ProtectedRoute;
