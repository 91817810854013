import { BehaviorSubject, Observable } from "rxjs";
import * as localForage from "localforage";

import {
  DB_SETTINGS,
  DB_SETTINGS_FLAG,
  DEMO_DEFAULT_SETTINGS,
  SETTINGS_STATE_FLAGS,
  SettingsService
} from "./settings.service";
import { MenuService } from "./menu.service";
import { DemoStates } from "../models/demo.types";
import { DemoSettings } from "../models/settings.types";

class _DemoService {
  DEMO_STATES: DemoStates = {
    READY: "ready",
    INITIALIZING: "initializing",
    INITIALIZED: "initialized"
  };

  private demoStateSubject: BehaviorSubject<string> = new BehaviorSubject(
    this.DEMO_STATES.INITIALIZING
  );
  observeDemoState$ = (): Observable<string> =>
    this.demoStateSubject.asObservable();

  get demoState(): string {
    return this.demoStateSubject.getValue();
  }

  set demoState(newState: string) {
    this.demoStateSubject.next(newState);
  }

  async init(): Promise<void> {
    const settingsValues: string[] = Object.values(SettingsService.settings);
    const emptySettings: boolean = settingsValues.length === 0;
    const setFlag: string = await localForage.getItem(DB_SETTINGS_FLAG);
    const dbSettings: DemoSettings = await localForage.getItem(DB_SETTINGS);

    if (this.demoState === this.DEMO_STATES.INITIALIZING) {
      if (setFlag === SETTINGS_STATE_FLAGS.SET) {
        if (dbSettings) {
          SettingsService.update(dbSettings);
          this.transitionToReady();
          return;
        } else {
          await localForage.setItem(
            DB_SETTINGS_FLAG,
            SETTINGS_STATE_FLAGS.UNSET
          );
        }
      }

      if (emptySettings) {
        if (dbSettings) {
          SettingsService.update(dbSettings);
        } else {
          SettingsService.update(DEMO_DEFAULT_SETTINGS);
        }
      } else {
        SettingsService.update(SettingsService.settings);
      }

      await this.transitionToInitialized();
    }
  }

  transitionToInitializing = async (): Promise<void> => {
    await MenuService.clearError();
    await localForage.setItem(DB_SETTINGS_FLAG, SETTINGS_STATE_FLAGS.UNSET);
    DemoService.demoState = DemoService.DEMO_STATES.INITIALIZING;
  };

  transitionToInitialized = async (): Promise<void> => {
    DemoService.demoState = DemoService.DEMO_STATES.INITIALIZED;
  };

  transitionToReady = async (): Promise<void> => {
    DemoService.demoState = DemoService.DEMO_STATES.READY;
  };
}

export const DemoService: _DemoService = new _DemoService();
