import React from "react";
import { combineLatest, Subscription } from "rxjs";

import View from "../layout/view";
import Content from "../layout/content";
import GridItem from "../grid-item";
import Grid from "../grid";

import { MenuService } from "../../services/menu.service";
import { AuthService } from "../../services/auth.service";
import { MenuError, MenuItem, MenuItems } from "../../models/menu.types";
import { UserInfo } from "../../models/auth.types";
import { History } from "history";

interface IMenuViewProps {
  history: History;
}

interface IMenuViewState {
  menuItems: MenuItem[];
  errorMessage: string;
  isAuth: boolean;
  isMenuAdmin: boolean;
  loading: boolean;
}

export default class MenuView extends React.Component<
  IMenuViewProps,
  IMenuViewState
> {
  state = {
    menuItems: [],
    errorMessage: "",
    isAuth: false,
    isMenuAdmin: false,
    loading: true
  };

  dataSub: Subscription | null = null;

  async componentDidMount(): Promise<void> {
    this.dataSub = combineLatest([
      AuthService.observeIsAuth$(),
      AuthService.observeUser$(),
      MenuService.observeMenu$(),
      MenuService.observeMenuError$()
    ]).subscribe(
      (data: [boolean, UserInfo, MenuItems, MenuError | undefined]) => {
        const [isAuth, userInfo, menuData, menuError]: [
          boolean,
          UserInfo,
          MenuItems,
          MenuError | undefined
        ] = data;

        if (menuError && menuError.error) {
          this.setState({
            errorMessage: `${menuError.message}. Unable to retrieve menu information.`,
            loading: false
          });
          return;
        }

        const menuItems: MenuItem[] = Object.values(menuData);

        this.setState({
          isAuth,
          menuItems,
          isMenuAdmin: userInfo.isMenuAdmin,
          loading: false
        });
      }
    );
  }

  componentWillUnmount(): void {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  addMenuItem = (): void => {
    this.props.history.push(`/menu/add-item`);
  };

  render() {
    const body = (
      <>
        {this.state.menuItems ? (
          <Grid>
            {this.state.menuItems.map((menuItem: MenuItem) => (
              <GridItem
                key={menuItem.id}
                {...menuItem}
                content={menuItem.image}
              />
            ))}
          </Grid>
        ) : null}

        {this.state.errorMessage && <span>{this.state.errorMessage}</span>}
      </>
    );

    return (
      !this.state.loading && (
        <View>
          {this.state.isAuth &&
          this.state.isMenuAdmin &&
          this.state.menuItems ? (
            <Content
              title="Menu Items"
              actionName="Add Item"
              action={this.addMenuItem}
            >
              {body}
            </Content>
          ) : (
            <Content title="Menu Items">{body}</Content>
          )}
        </View>
      )
    );
  }
}
