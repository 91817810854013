import React from "react";

import View, { ViewStates } from "../layout/view";
import Content from "../layout/content";
import Form from "../form/form";
import { MenuService } from "../../services/menu.service";
import Button from "../button";
import { MenuError, MenuItem } from "../../models/menu.types";

import { History } from "history";
import { Subscription } from "rxjs";
import { IFormFields, IFormValues } from "../form/form.service";

interface IEditItemViewState {
  loading: boolean;
  menuItem: MenuItem | undefined;
  viewStatus: ViewStates.Valid | ViewStates.NotFound | undefined;
  menuItemId: number;
  errorMessage: string;
}

interface IEditItemViewProps {
  history: History;
  computedMatch: {
    params: {
      menuItemId: string;
    };
  };
}

class EditItemView extends React.Component<
  IEditItemViewProps,
  IEditItemViewState
> {
  state = {
    loading: true,
    menuItem: undefined,
    viewStatus: undefined,
    menuItemId: parseInt(this.props.computedMatch.params.menuItemId, 10),
    errorMessage: ""
  };

  menuErrorSub: Subscription | null = null;
  menuErrorTimeout: any = null;

  MENU_ITEM_FIELDS: IFormFields = {
    name: {
      id: "name",
      name: "Name",
      validation: {
        validator: value => {
          return /^[A-Za-z ]+$/.test(value);
        },
        invalidMessage: "Invalid name"
      },
      isRequired: true
    },
    price: {
      id: "price",
      name: "Price",
      validation: {
        validator: value => {
          return /^([1-9]+[0-9]*|0)((\.[\d][\d])?)$/.test(value);
        },
        invalidMessage: "Invalid price"
      },
      isRequired: true
    },
    description: {
      id: "description",
      name: "Description",
      validation: {
        validator: value => {
          return /^[A-Za-z0-9 '".,;!?\-()]+$/.test(value);
        },
        invalidMessage: "Invalid description"
      },
      isRequired: true
    },
    image: {
      id: "image",
      name: "Image URL",
      validation: {
        validator: value => {
          return /^(https:\/\/).+(\.[a-z]{2,3}\/).+(\.(jpg|jpeg|png))$/.test(
            value
          );
        },
        invalidMessage: "Invalid image URL"
      },
      isRequired: true
    }
  };

  async componentDidMount() {
    this.menuErrorSub = MenuService.observeMenuError$().subscribe(
      (menuError: MenuError | undefined) => {
        if (menuError && menuError.error) {
          this.setState({ errorMessage: menuError.message });

          this.menuErrorTimeout = setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 5000);
        }
      }
    );

    let menuItem: MenuItem | undefined;
    let viewStatus: ViewStates.Valid | ViewStates.NotFound;
    let menuItemId: number = this.state.menuItemId;

    if (MenuService.selectedMenuItem) {
      menuItem = MenuService.selectedMenuItem;
    } else {
      menuItem = await MenuService.getMenuItem(menuItemId);
    }

    if (menuItem) {
      viewStatus = ViewStates.Valid;
    } else {
      viewStatus = ViewStates.NotFound;
    }

    this.setState({
      viewStatus,
      menuItem,
      loading: false,
      menuItemId
    });
  }

  componentWillUnmount(): void {
    if (this.menuErrorSub) {
      this.menuErrorSub.unsubscribe();
    }

    if (this.menuErrorTimeout) {
      clearTimeout(this.menuErrorTimeout);
    }
  }

  updateMenuItem = async (payload: IFormValues) => {
    const item: MenuItem = {
      id: this.state.menuItemId,
      name: payload.name,
      description: payload.description,
      image: payload.image,
      price: parseFloat(payload.price)
    };

    await MenuService.updateMenuItem(item);
  };

  cancel = async () => {
    await this.props.history.push(`/menu/${this.state.menuItemId}`);
  };

  render() {
    return (
      !this.state.loading && (
        <>
          {this.state.viewStatus === ViewStates.NotFound && (
            <View viewStatus={this.state.viewStatus}>
              <Button
                label="View menu items"
                action={() => this.props.history.push("/menu")}
              />
            </View>
          )}

          {this.state.viewStatus === ViewStates.Valid && (
            <View viewStatus={this.state.viewStatus}>
              <Content
                title="Edit Menu Item"
                actionName="Cancel"
                action={this.cancel}
              >
                {this.state.errorMessage && (
                  <span>{this.state.errorMessage}</span>
                )}
                <Form
                  fields={this.MENU_ITEM_FIELDS}
                  values={this.state.menuItem}
                  onSubmit={this.updateMenuItem}
                />
              </Content>
            </View>
          )}
        </>
      )
    );
  }
}

export default EditItemView;
