import React from "react";
import { combineLatest, Subscription } from "rxjs";

import Content from "../layout/content";

import View from "../layout/view";

import { AuthService } from "../../services/auth.service";

import { UserInfo } from "../../models/auth.types";

interface IHomeViewState {
  isAuth: boolean;
  firstName: string;
}

export default class HomeView extends React.Component<{}, IHomeViewState> {
  state: IHomeViewState = {
    isAuth: false,
    firstName: ""
  };

  dataSub: Subscription | null = null;

  componentDidMount() {
    this.dataSub = combineLatest([
      AuthService.observeIsAuth$(),
      AuthService.observeUser$()
    ]).subscribe((data: [boolean, UserInfo]) => {
      const isAuth = data[0];
      const firstName = data[1].firstName || "Whatabyter";

      this.setState({ isAuth, firstName });
    });
  }

  componentWillUnmount() {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  render() {
    return (
      <View>
        {this.state.isAuth ? (
          <Content title={`Hello, ${this.state.firstName}`} />
        ) : (
          <Content title="Howdy">
            <h3>To start using the Dashboard, please sign in.</h3>
          </Content>
        )}
      </View>
    );
  }
}
