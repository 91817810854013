import { BehaviorSubject, Observable } from "rxjs";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { SettingsService } from "./settings.service";
import { AuthService } from "./auth.service";
import history from "../history";
import { MenuError, MenuItem, MenuItems } from "../models/menu.types";

class _MenuService {
  _selectedMenuItem: MenuItem | undefined = undefined;

  private menuSubject: BehaviorSubject<MenuItems> = new BehaviorSubject({});
  observeMenu$ = (): Observable<MenuItems> => this.menuSubject.asObservable();

  private menuErrorSubject: BehaviorSubject<
    MenuError | undefined
  > = new BehaviorSubject<MenuError | undefined>(undefined);
  observeMenuError$ = (): Observable<MenuError | undefined> =>
    this.menuErrorSubject.asObservable();

  get selectedMenuItem(): MenuItem | undefined {
    return this._selectedMenuItem;
  }

  set selectedMenuItem(newSelectedItem: MenuItem | undefined) {
    this._selectedMenuItem = newSelectedItem;
  }

  getMenuItem = async (id: number): Promise<MenuItem | undefined> => {
    const state: MenuItems = this.menuSubject.getValue();
    const item: MenuItem = state[id];

    if (item) {
      this.selectedMenuItem = item;
      return item;
    }

    return undefined;
  };

  addMenuItem = async (payload: MenuItem) => {
    const token: string | null = await AuthService.getTokenSilently();
    const baseUrl: string = SettingsService.apiUrl;
    const url: string = `${baseUrl}/items`;

    if (token) {
      const options: AxiosRequestConfig = {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          item: payload
        },
        url
      };

      try {
        const response: AxiosResponse = await axios(options);
        const { status }: { status: number } = response;

        if (status === 201) {
          await this.fetchMenu();
          history.push("/menu");
        }
      } catch (e) {
        this.menuSubject.next({});
        this.menuErrorSubject.next({ error: true, message: e.message });
      }
    }
  };

  updateMenuItem = async (payload: MenuItem) => {
    const token: string | null = await AuthService.getTokenSilently();
    const baseUrl: string = SettingsService.apiUrl;
    const url: string = `${baseUrl}/items`;

    if (token) {
      const options: AxiosRequestConfig = {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          item: payload
        },
        url
      };

      try {
        const response: AxiosResponse = await axios(options);
        const { status }: { status: number } = response;

        if (status === 200) {
          await this.fetchMenu();
          history.push("/menu");
        }
      } catch (e) {
        this.menuSubject.next({});
        this.menuErrorSubject.next({
          error: true,
          message: `Unable to update menu item.`
        });
      }
    }
  };

  deleteMenuItem = async (itemId: number) => {
    const token: string | null = await AuthService.getTokenSilently();
    const baseUrl: string = SettingsService.apiUrl;

    if (token) {
      const items: MenuItems = this.menuSubject.getValue();
      const item: MenuItem = items[itemId];

      if (item) {
        const url: string = `${baseUrl}/items/${itemId}`;

        const options: AxiosRequestConfig = {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
          url
        };

        try {
          const response: AxiosResponse = await axios(options);
          const { status }: { status: number } = response;

          if (status === 200) {
            await this.fetchMenu();
            history.push("/menu");
          }
        } catch (e) {
          this.menuSubject.next({});
          this.menuErrorSubject.next({ error: true, message: e.message });
        }
      } else {
        this.menuSubject.next({});
        this.menuErrorSubject.next({
          error: true,
          message: "Can't delete item"
        });
      }
    }
  };

  fetchMenu = async () => {
    const baseUrl: string = SettingsService.apiUrl;

    try {
      const response: AxiosResponse = await axios.get(`${baseUrl}/items`);
      const { data }: { data: MenuItems } = response;

      if (data) {
        this.menuSubject.next(data);
      }
    } catch (e) {
      this.menuSubject.next({});
      this.menuErrorSubject.next({ error: true, message: e.message });
    }
  };

  clearError = async () => {
    this.menuErrorSubject.next(undefined);
  };
}

export const MenuService = new _MenuService();
