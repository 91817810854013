import React from "react";
import {
  Router,
  Route,
  Redirect,
  Switch,
  RouteComponentProps
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons/faStroopwafel";
import history from "./history";

import HomeView from "./components/views/home-view";
import MenuView from "./components/views/menu-view";
import EditItemView from "./components/views/edit-item-view";
import NotFoundView from "./components/views/not-found-view";
import SettingsView from "./components/views/settings-view";
import UserProfileView from "./components/views/user-profile-view";
import AddItemView from "./components/views/add-item-view";

import NavBar from "./components/layout/nav-bar";
import MenuItem from "./components/views/menu-item";
import ProtectedRoute from "./components/protected-route";
import MenuAdminRoute from "./components/menu-admin-route";

import { AppService } from "./services/app.service";
import { AuthService } from "./services/auth.service";

import { Subscription } from "rxjs";

import "./app.scss";

interface IAppState {
  loading: boolean;
}

class App extends React.Component {
  state: IAppState = {
    loading: true
  };

  APP_NAME: string = `WHATABYTE`;

  appStateSubscription: Subscription | null = null;
  authStateSubscription: Subscription | null = null;

  async componentDidMount() {
    this.appStateSubscription = AppService.observeAppState$().subscribe(() => {
      this.setState({ loading: false }, () => {
        if (AppService.isIdle()) {
        }

        if (AppService.isInitializing()) {
          AppService.init();
        }
      });
    });

    this.authStateSubscription = AuthService.observeAuthState$().subscribe(
      (authState: string) => {
        this.setState({ authState });
      }
    );
  }

  componentWillUnmount() {
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }

    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  render() {
    return (
      !this.state.loading && (
        <>
          <div className="App">
            <div id="mask" />
            {AppService.isInitialized() && (
              <>
                {AuthService.isReady() && (
                  <>
                    <Router history={history}>
                      <NavBar appName={this.APP_NAME} />
                      <Switch>
                        <Redirect exact to="/home" from="/" />
                        <Route path="/home" component={HomeView} />
                        <Route
                          path="/menu"
                          render={(props: RouteComponentProps) => {
                            const path = props.match.path;

                            return (
                              <Switch>
                                <Route
                                  exact
                                  path={`${path}/`}
                                  component={MenuView}
                                />
                                <MenuAdminRoute
                                  {...props}
                                  redirectPath="/menu"
                                  path={`${path}/add-item`}
                                  component={AddItemView}
                                />
                                <MenuAdminRoute
                                  {...props}
                                  redirectPath="/menu"
                                  path={`${path}/:menuItemId/edit-item`}
                                  component={EditItemView}
                                />
                                <Route
                                  path={`${path}/:menuItemId`}
                                  component={MenuItem}
                                />
                              </Switch>
                            );
                          }}
                        />

                        <Route path="/settings" component={SettingsView} />
                        <ProtectedRoute
                          path="/user-profile"
                          component={UserProfileView}
                        />
                        <Route component={NotFoundView} />
                      </Switch>
                    </Router>
                  </>
                )}

                {AuthService.isInitializing() && (
                  <div className="App__loader">
                    <FontAwesomeIcon icon={faStroopwafel} spin />
                  </div>
                )}
              </>
            )}
            {AppService.isInitializing() && (
              <div className="App__loader">
                <FontAwesomeIcon icon={faStroopwafel} spin />
              </div>
            )}
          </div>
        </>
      )
    );
  }
}

export default App;
